//#region "IMPORTS"
import React, { useState, useEffect } from 'react'
import SimpleBootstrapModal from '../SimpleBootstrapModal'
import TextInputCustom from '../TextInputCustom'
import ButtonSave from '../ButtonSave'
import ButtonModal from '../ButtonModal'
import CustomSelect from '../CustomSelect'
import UserEdition from './UserEdition'
import { useUserEditions } from '../../../Hooks/userUserEditions'
import { useUsers } from '../../../Hooks/useUsers'
import { useCasesTeachers } from '../../../Hooks/useCasesTeachers'
import { useContext } from 'react'
import { SearchContext } from '../../../Contexts/Search/SearchContext'
import { TableList } from '../Table/TableList'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import moment from 'moment'
//#endregion

export default ({ creating = false, title, imageUrl, roles,
    casesEditions, casesTypes, teacherCasesTypes, inputModalContent,
    setUsers, setTeacherCasesTypes }) => {

    const BG_TAB_NO_ACTIVE = '#e3e3e3'
    const PADDING_TAB = ".35rem"
    const DEFAULT_VALUE_DP = { value: -1, label: "Todos" }
    const searchContext = useContext(SearchContext)
    const [limitCases, setLimitCases] = useState()
    const [invisalignPasswordVisibility, setInvisalignPasswordVisibility] = useState(false)
    const [selectedCaseType, setSelectedCaseType] = useState({})
    const [modalContent, setModalContent] = useState({
        id: "",
        name: "",
        lastname: "",
        vat: "",
        email: "",
        phone: "",
        password: "",
        profilePhoto: "",
        userInvisalign: "",
        passwordInvisalign: "",
        descriptionNotes: "",
        internalNotes: "",
        created: "",
        modified: "",
        validated: "",
        enabled: "",
        role: "",
        pause_assignment: "",
        userEditions: []
    })
    const [passwordInvisalignInputType, setPasswordInvisalignInputType] = useState("password")
    const [passwordInputType, setPasswordInputType] = useState("password")
    const [selectedEdition, setSelectEdition] = useState()
    const [selectedTab, setSelectedTab] = useState("userDataTab")
    const [pristinityCheck, setPristinityCheck] = useState({
        edition: true,
        limit: true
    })
    let history = useHistory()

    useEffect(() => {
        setModalContent(inputModalContent)
    }, [inputModalContent])

    const { retrieveCaseTeacher, assignTeacher, unassignTeacher } = useCasesTeachers()

    const { retrieveUserEditions, createUserEdition, removeUserEdition, updateUserEdition } = useUserEditions()

    const { retrieveUsers, updateUser, createUserAdmin, removeUserByAdmin } = useUsers()


    const handleInputChange = e => {
        let name = e.target.name
        let value = e.target.value

        setModalContent({ ...modalContent, [name]: value })
    }

    const handleRoleSelect = e => {
        setModalContent({ ...modalContent, role: e.value })
    }

    const handleCheckboxChange = e => {
        let name = e.target.name
        let checked = e.target.checked
        setModalContent({ ...modalContent, [name]: name !== 'pause_assignment' ? checked : !checked })
        if (checked && name !== 'pause_assignment') {
            toast.warning("Agregar edición al usuario")
        }
    }

    const handleEditionSelect = e => {
        if (e.number) {
            setSelectEdition(e.value)
            setLimitCases(e.number)
            setPristinityCheck({
                ...pristinityCheck, edition: false, limit: false
            })
        } else {
            setLimitCases(e.target.value)
            setPristinityCheck({
                ...pristinityCheck, limit: false
            })
        }
    }

    const handleUpdateUserEdition = async (idEdition, limitCases) => {
        await updateUserEdition(modalContent.id, idEdition, limitCases)
        setModalContent({
            ...modalContent,
            userEditions: await retrieveUserEditions(modalContent.id)
        })
    }

    const handleRemoveUserEdition = async idEdition => {
        let result = await removeUserEdition(modalContent.id, idEdition)
        if (result) {
            setModalContent({ ...modalContent, userEditions: await retrieveUserEditions(modalContent.id) })
        }
    }

    const handleAddUserEdition = async e => {
        if (Number.isInteger(selectedEdition) && Number.isInteger(limitCases)) {
            let result = await createUserEdition(modalContent.id, selectedEdition, limitCases)
            if (result)
                setModalContent({
                    ...modalContent,
                    userEditions: await retrieveUserEditions(modalContent.id)
                })
            document.getElementById("triggerModalAsignedEdition").click()
        }
    }

    const updateUserList = async () => {
        let usersList = await retrieveUsers()
        usersList.map(async user => {
            user.Editions = await retrieveUserEditions(user.id)
            return user
        })
        setUsers(usersList)
        //handleFilters({}, usersList)
        document.getElementById("closeUserModal").click()
    }

    const updateUserData = async () => {
        if (await updateUser(modalContent)) {
            await updateUserList()
        }
    }

    const createUser = async () => {
        if (await createUserAdmin(modalContent)) {
            await updateUserList()
        }
    }

    const deleteUser = async () => {
        if (modalContent?.count_case === 0) {
            if (await removeUserByAdmin(modalContent.id)) {
                document.getElementById("closeDeleteUserEditionModal").click()
                await updateUserList()
            }
        } else {
            toast.warn("Este usuario tiene casos asignados.")
        }
    }

    const closeFunction = () => {
        document.getElementById("userDataTab").click()
        setSelectEdition()
        setLimitCases()
    }

    const changeInvisalignPasswordVisibility = () => {
        setInvisalignPasswordVisibility(!invisalignPasswordVisibility)
        if (passwordInvisalignInputType === "text") {
            setPasswordInvisalignInputType("password")
        } else {
            setPasswordInvisalignInputType("text")
        }
    }


    const eyeVisibleDivStyles = {
        display: "flex",
        alignItems: "center",
        marginTop: "1.5rem",
        marginLeft: "0.5rem"
    }

    const selectCaseType = e => {
        setSelectedCaseType(e)
    }

    const addCaseAssignation = async () => {
        if (
            Number.isInteger(selectedCaseType.value) &&
            await assignTeacher(modalContent.id, selectedCaseType.value)
        ) {
            setTeacherCasesTypes(await retrieveCaseTeacher(modalContent.id))
        }
    }

    const deleteCaseAssignation = async idCaseType => {
        if (await unassignTeacher(modalContent.id, idCaseType)) {
            setTeacherCasesTypes(await retrieveCaseTeacher(modalContent.id))
        }
    }

    const ItemTab = ({ onclick, title, id, href, visible = true }) => (
        visible ?
            <li className="nav-item">
                <a className="nav-link font-size1-1"
                    onClick={onclick}
                    style={{
                        padding: PADDING_TAB, border: 1, borderBottom: selectedTab === id ? 0 : 1,
                        borderColor: 'black', borderStyle: 'solid', color: 'black', fontWeight: selectedTab === id ? 'bold' : 'normal',
                        borderBottomStyle: selectedTab === id ? 'initial' : 'solid',
                        background: selectedTab === id ? null : BG_TAB_NO_ACTIVE
                    }}
                    data-bs-toggle="tab" href={href} id={id}>
                    {title}
                </a>
            </li > : null
    )

    const ContentTabs = () => (
        <ul className="nav nav-tabs nav-fill" style={{ border: 0 }}>
            <ItemTab
                href={"#userData"} id={"userDataTab"}
                title={"Datos"}
                onclick={() => setSelectedTab('userDataTab')}
            />
            <ItemTab
                href={"#userEditions"} id={"userEditionsTab"}
                title={"Ediciones"}
                onclick={() => setSelectedTab('userEditionsTab')}
            />

            <ItemTab
                visible={modalContent.role === 2 || modalContent.role === 1}
                href={"#casesTypes"} id={"casesTypesTab"}
                title={"Tipos de caso"}
                onclick={() => setSelectedTab('casesTypesTab')}
            />
            <ItemTab
                href={"#cases"} id={"casesTab"}
                title={`Casos (${modalContent.count_case})`}
                onclick={() => {
                    searchContext.setSearchData({
                        valueDp: modalContent.role.toString(),
                        textFilter: modalContent.name + ' ' + modalContent.lastname,
                        stateFilter: { value: 0, label: "Todos" },
                        caseTypeFilter: DEFAULT_VALUE_DP,
                        caseEditionFilter: DEFAULT_VALUE_DP
                    })
                    setSelectedTab('casesTab')
                    document.getElementById("closeUserModal").click()
                    history.push("/main-screen/cases")
                }}
            />
            <ItemTab
                id={"caseAssessmentsTab"}
                title={`Valoraciones (${modalContent.count_case_assessments})`}
                onclick={() => {
                    setSelectedTab('caseAssessmentsTab')
                    document.getElementById("closeUserModal").click()
                    history.push({
                        pathname: "/main-screen/cases-assessments", state: {
                            idUser: modalContent.id,
                            typeUser: modalContent.role,
                            nameUser: modalContent.name + ' ' + modalContent.lastname
                        }
                    })
                }}
            />
            <ItemTab
                id={"ratingTab"}
                title={`Reseña (${modalContent.count_reponse_rating})`}
                onclick={() => {
                    setSelectedTab('ratingTab')
                    document.getElementById("closeUserModal").click()
                    history.push({
                        pathname: "/main-screen/ratings", state: {
                            idUser: modalContent.id,
                            typeUser: modalContent.role,
                            nameUser: modalContent.name + ' ' + modalContent.lastname
                        }
                    })
                }}
            />
        </ul>
    )

    const HeadList = () => (
        <tr>
            <th></th>
            <th style={{ minWidth: 250 }}>{"Edición"}</th>
            <th style={{ width: 80, minWidth: 80, textAlign: "center" }}>{"Consumido"}</th>
            <th style={{ width: 80, minWidth: 80, textAlign: "center" }}>{"Límite"}</th>
            <th style={{ width: 200, minWidth: 200, textAlign: "center" }}>{"Acciones"}</th>
            <th style={{ width: 120, minWidth: 120, textAlign: "center" }}>{"Estado"}</th>
        </tr>
    )

    return ([
        <ButtonSave buttonId="triggerModalAsignedEdition" dataTarget="#confirmAsignedEdition" cssClasses="none" />,
        <SimpleBootstrapModal 
            id={"confirmAsignedEdition"} 
            title={"Confirmación"} 
            message={'Se asigno la edición correctamente.'} 
            cancelButtonMessage="Aceptar" 
            closeButtonId="closeAsignedEdition" />,

        <SimpleBootstrapModal
            id={"userModal"}
            title={title} imageUrl={imageUrl}
            closeButtonId="closeUserModal" fullScreen={true}
            closeFunction={closeFunction}
            footerButtons={[
                !creating && <ButtonSave textBtn={"Eliminar"} formGroup={false} dataTarget={'#deleteUserEditionModal'} buttonClasses={"light-red"} />,
                <ButtonModal text={"Guardar"} onClick={!creating ? updateUserData : createUser} />
            ]}
        >
            <ContentTabs />
            <div className="card-body" style={{ border: 1, borderTop: 0, borderColor: "black", borderStyle: "solid" }}>
                <div id="myTabContent" className="tab-content">
                    <div className="tab-pane fade show active" id="userData">
                        <div className="form-group">
                            <div className={"row form-group "}>
                                <div className="col-sm form-group">
                                    <TextInputCustom
                                        textLabel={"Nombre"}
                                        nameInput={"name"}
                                        typeInput={"text"}
                                        required={true}
                                        cssClassesGroup={"margin0"}
                                        value={modalContent.name}
                                        evOnChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-sm form-group">
                                    <TextInputCustom
                                        textLabel={"Apellido"}
                                        nameInput={"lastname"}
                                        typeInput={"text"}
                                        required={true}
                                        cssClassesGroup={"margin0"}
                                        value={modalContent.lastname}
                                        evOnChange={handleInputChange}

                                    />
                                </div>
                            </div>
                            <div className={"row form-group "}>
                                <div className="col-sm form-group">
                                    <TextInputCustom
                                        textLabel={"DNI"}
                                        nameInput={"vat"}
                                        typeInput={"text"}
                                        required={true}
                                        cssClassesGroup={"margin0"}
                                        value={modalContent.vat}
                                        evOnChange={handleInputChange}

                                    />
                                </div>
                                <div className="col-sm form-group">
                                    <TextInputCustom
                                        textLabel={"Email"}
                                        nameInput={"email"}
                                        typeInput={"text"}
                                        required={true}
                                        cssClassesGroup={"margin0"}
                                        value={modalContent.email}
                                        evOnChange={handleInputChange}

                                    />
                                </div>
                            </div>
                            <div className={"row form-group "}>
                                <div className="col-sm form-group">
                                    <TextInputCustom
                                        textLabel={"Contraseña"}
                                        nameInput={"password"}
                                        typeInput={passwordInputType || "password"}
                                        required={true}
                                        cssClassesGroup={"margin0"}
                                        placeholder={!creating ? "***********" : ""}
                                        value={modalContent.password}
                                        evOnChange={handleInputChange}
                                    />
                                </div>
                                <div className="col-sm form-group">
                                    <TextInputCustom
                                        textLabel={"Teléfono"}
                                        nameInput={"phone"}
                                        typeInput={"number"}
                                        required={true}
                                        cssClassesGroup={"margin0"}
                                        value={modalContent.phone}
                                        evOnChange={handleInputChange}

                                    />
                                </div>
                            </div>
                            <div className="row form-group">
                                <div className="col">
                                    <CustomSelect
                                        data={typeof roles != "undefined" && roles.map(role => {
                                            return { value: role.id, label: role.Name }
                                        })}
                                        value={typeof roles != "undefined" && roles.map(role => {
                                            if (modalContent.role === role.id) {
                                                return { value: role.id, label: role.Name }
                                            }
                                        })}
                                        name="role"
                                        label="Rol"
                                        divClasses="marginlr0 field marginb05"
                                        onChange={handleRoleSelect}
                                    />
                                </div>
                            </div>
                            {
                                modalContent.role != 1 && modalContent.role != 2
                                    ?
                                    <div className={"row form-group "}>
                                        <div className="col-sm form-group">
                                            <TextInputCustom
                                                textLabel={"Usuario Invisalign"}
                                                nameInput={"userInvisalign"}
                                                typeInput={"text"}
                                                required={true}
                                                cssClassesGroup={"margin0"}
                                                value={modalContent.userInvisalign}
                                                evOnChange={handleInputChange}

                                            />
                                        </div>
                                        <div className="col-sm form-group">
                                            <div className="flex">
                                                <TextInputCustom
                                                    textLabel={"Contraseña Invisalign"}
                                                    nameInput={"passwordInvisalign"}
                                                    typeInput={passwordInvisalignInputType || "password"}
                                                    required={true}
                                                    cssClassesGroup={"margin0"}
                                                    value={modalContent.passwordInvisalign}
                                                    evOnChange={handleInputChange}
                                                />
                                                {invisalignPasswordVisibility ?
                                                    <div
                                                        className="clickable"
                                                        style={eyeVisibleDivStyles}
                                                        onClick={changeInvisalignPasswordVisibility}
                                                    >
                                                        <i className="clickable hovereableIcon fas fa-eye"></i>
                                                    </div>
                                                    : null
                                                }

                                                {!invisalignPasswordVisibility ?
                                                    <div
                                                        className="clickable"
                                                        style={eyeVisibleDivStyles}
                                                        onClick={changeInvisalignPasswordVisibility}
                                                    >
                                                        <i className="clickable hovereableIcon fas fa-eye-slash"></i>
                                                    </div>
                                                    : null}
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                            }
                            <div className={"row form-group "}>
                                <div className="col">
                                    <label htmlFor="descriptionNotes">Especialidad dental: </label>
                                    <textarea
                                        className="margin0 width100"
                                        name="descriptionNotes"
                                        id="descriptionNotes"
                                        value={modalContent.descriptionNotes}
                                        onChange={handleInputChange}

                                    />
                                </div>
                            </div>
                            <div className={"row form-group "}>
                                <div className="col">
                                    <label htmlFor="internalNotes">Notas internas: </label>
                                    <textarea
                                        className="margin0 width100"
                                        name="internalNotes"
                                        id="internalNotes"
                                        value={modalContent.internalNotes}
                                        onChange={handleInputChange}

                                    />
                                </div>
                            </div>
                            <div className={"row form-group "}>
                                <div className="col-sm form-group">
                                    <label htmlFor="validated"
                                        className="marginr05">Validado: </label>
                                    <input type="checkbox"
                                        className="margin0 form-check-input middle"
                                        name="validated" id="validated"
                                        checked={!creating ? modalContent.validated : true}
                                        disabled />
                                </div>
                                <div className="col-sm form-group">
                                    <label htmlFor="enabled"
                                        className="marginr05">Habilitado: </label>
                                    <input type="checkbox"
                                        className="margin0 form-check-input middle"
                                        name="enabled" id="enabled"
                                        onChange={handleCheckboxChange}
                                        checked={modalContent.enabled}
                                    />
                                </div>
                                {modalContent.role === 2 && <div className="col-sm form-group">
                                    <label htmlFor="pause_assignment"
                                        className="marginr05">Pausado: </label>
                                    <input type="checkbox"
                                        className="margin0 form-check-input middle"
                                        name="pause_assignment" id="pause_assignment"
                                        onChange={handleCheckboxChange}
                                        checked={!modalContent.pause_assignment}
                                    />
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="userEditions">
                        <div className="row">
                            <div className="col col-sm6">
                                <CustomSelect
                                    data={casesEditions.sort((a,b) => {
                                        if(a.value < 0){
                                            return 1
                                        }
                                        if(a.label.toLowerCase() < b.label.toLowerCase()){
                                            return -1
                                        }
                                        if(a.label.toLowerCase() > b.label.toLowerCase()){
                                            return 1
                                        }
                                        return 0;
                                    })}
                                    name="editions"
                                    label="*Ediciones"

                                    divClasses="marginlr0 field marginb05"
                                    onChange={handleEditionSelect}
                                />
                            </div>
                            <div className="col col-sm6">
                                <TextInputCustom
                                    textLabel={"Límite de casos"}
                                    nameInput={"limitCases"}
                                    typeInput={"number"}
                                    required={true}
                                    cssClassesGroup={"margin0"}
                                    value={limitCases}
                                    evOnChange={handleEditionSelect}
                                />
                            </div>
                        </div>
                        <ButtonSave
                            textBtn="Agregar"
                            cssClasses="marginlr0"
                            icoClasses={"fas fa-plus"}
                            handleSubmit={handleAddUserEdition}
                        />
                        <div className="row">
                            <TableList
                                headList={<HeadList />}
                                bodyList={
                                    modalContent?.userEditions instanceof Array &&
                                    modalContent.userEditions.sort((a, b) => {
                                        const dateA = moment(a.End_date).format('DD-MM-YYYY hh:mm:ss')
                                        const dateB = moment(b.End_date).format('DD-MM-YYYY hh:mm:ss')
                                        return dateB < dateA ? 1 : -1
                                    }).map((userEdition, index) => {
                                        console.log("🚀 ~ index:", index)
                                        console.log("🚀 ~ userEdition:", userEdition.Name, moment(userEdition.End_date).format('DD-MM-YYYY hh:mm:ss'))

                                        return (
                                            <UserEdition
                                                index={index}
                                                user_edition={userEdition}
                                                updateMethod={handleUpdateUserEdition}
                                                removeMethod={handleRemoveUserEdition}
                                            />
                                        )
                                    })
                                }
                            />

                        </div>
                    </div>
                    <div className="tab-pane fade" id="casesTypes">
                        <CustomSelect
                            label="*Tipo de caso"
                            name="caseType"
                            divClasses="marginlr0 field marginb05"
                            data={casesTypes}
                            onChange={selectCaseType}
                        />
                        <ButtonSave
                            textBtn="Agregar"
                            cssClasses="marginlr0"
                            icoClasses={"fas fa-plus"}
                            handleSubmit={addCaseAssignation}
                        />
                        {
                            teacherCasesTypes.map((teacherCaseType, index) => {
                                return (
                                    <div className="row marginb1" key={index}>
                                        <div className="col-10">
                                            {teacherCaseType.name}
                                        </div>
                                        <div className="col-2">
                                            <ButtonSave
                                                icoClasses={"fas fa-trash-alt"}
                                                cssClasses={"margin0"}
                                                buttonClasses={"light-red"}
                                                handleSubmit={() => deleteCaseAssignation(teacherCaseType.id)}
                                            />
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </SimpleBootstrapModal>,
        <SimpleBootstrapModal
            id={"deleteUserEditionModal"}
            title={`Eliminar Usuario`}
            message={[
                <span>Si el usuario tiene ediciones asignadas, se eliminarán.</span>,
                <br />,
                <span>¿Estás segura de que quieres eliminar este usuario?</span>
            ]}
            closeButtonId="closeDeleteUserEditionModal"
            footerCancelButton={false}
            footerButtons={[
                <ButtonModal id={"goBackUserEditions"} text={"No"} bsTarget={"#userModal"} close={true} />,
                <ButtonSave textBtn={"Sí"} formGroup={false} handleSubmit={deleteUser} />
            ]}
        />
    ])
}